import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import { withPrefix } from 'gatsby-link';

import SiteComponent from '../components/SiteComponent';
import { mapDispatchToProps } from '../services/MapDispatchToProps';
import { mapStateToProps } from '../services/MapStateToProps';
import HomeComponent from '../components/HomeComponent';

class IndexPage extends SiteComponent {
	constructor(args) {
		super(args, false);

		// Skip build, Browsers only
		if (typeof window !== 'undefined') {
			const langKey = args.state.lang;
			if (langKey !== 'it') {
				const homeUrl = withPrefix(`/${langKey}/`);
				window.___replace(homeUrl);
			}
			args.reduxFunctions.swithcLanguage(langKey);
		}
	}

	render() {
		return <HomeComponent data={this.props.data} />;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage);

export const pageQuery = graphql`
	query {
		linksPages: allWordpressPage {
			edges {
				node {
					id
					title
					polylang_current_lang_code
					slug
				}
			}
		}
		page: wordpressWpHome(polylang_current_lang_code: { eq: "it" }) {
			title
			slug
			content
			date(formatString: "MMMM DD, YYYY")
			language: polylang_current_lang_code
			polylang_translations {
				id
				slug
				title
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
			acf {
				cover_title
				cover_image {
					source_url
					localFile {
						childImageSharp {
							original {
								src
							}
							resolutions(width: 100, height: 100) {
								...GatsbyImageSharpResolutions_withWebp_tracedSVG
							}
							fluid(maxWidth: 500) {
								aspectRatio
								base64
								src
							}
						}
					}
				}
				certification {
					post_title
					acf {
						icon {
							source_url
							localFile {
								childImageSharp {
									original {
										src
									}
									resolutions(width: 400, height: 400) {
										...GatsbyImageSharpResolutions_withWebp_tracedSVG
									}
									fluid(maxHeight: 400) {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
						summary
					}
				}
				services {
					post_title
					acf {
						icon {
							source_url
							localFile {
								childImageSharp {
									original {
										src
									}
									resolutions(width: 500, height: 500) {
										...GatsbyImageSharpResolutions_withWebp_tracedSVG
									}
									fluid(maxWidth: 500) {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
						summary
					}
				}
				product {
					product_title
					product_image {
						source_url
						localFile {
							childImageSharp {
								original {
									src
								}
								resolutions(width: 500, height: 500) {
									...GatsbyImageSharpResolutions_withWebp_tracedSVG
								}
								fluid(maxWidth: 500) {
									sizes
									srcSet
									aspectRatio
									base64
									src
								}
							}
						}
					}
					product_info_1
					product_info_2
					product_info_3
					product_info_4
					pruduct_related {
						title: post_title
					}
				}
				business_area {
					business_area_title
					business_area_items {
						business_area_item_title
					}
				}
				inspiration {
					inspiration_title
					inspiration_image {
						source_url
						localFile {
							childImageSharp {
								original {
									src
								}
								resolutions(width: 500, height: 500) {
									...GatsbyImageSharpResolutions_withWebp_tracedSVG
								}
								fluid(maxWidth: 500) {
									sizes
									srcSet
									aspectRatio
									base64
									src
								}
							}
						}
					}
				}
			}
		}
		news: allWordpressPost(
			limit: 3
			sort: { fields: [date], order: DESC }
			filter: { polylang_current_lang_code: { eq: "it" } }
		) {
			edges {
				node {
					id
					title
					lang: polylang_current_lang_code
					date
					slug
					acf {
						summary
						cover_image {
							source_url
							localFile {
								childImageSharp {
									original {
										src
									}

									fluid(maxWidth: 500) {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
